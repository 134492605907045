<template>
    <div>
        <el-dialog :title="title" :visible.sync="open" width="700px" @close="commitOnClose">
            <el-form ref="saveOrEditForm" :model="saveData" label-width="150px" style="width:600px" :rules="rules">
                <el-form-item :label="isOrg?'代理商/机构编号':'代理商编号'" prop="agentNo2">
                    <el-select style="width:280px" v-model="saveData.agentNo2" filterable remote reserve-keyword
                        :disabled="!isAdd" @change="getUserName" placeholder="可根据代理商/机构编号模糊查询" :remote-method="remoteMethod"
                        :loading="loading">
                        <el-option v-for="item in options" :key="item.agentNo" :label="item.agentNo" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="isOrg?'代理商/机构名称':'代理商名称'" prop="userName">
                    {{ saveData.userName }}
                </el-form-item>
                <el-form-item label="加价类型" prop="adjustNo">
                    <el-select v-model="saveData.adjustNo" placeholder="请选择加价类型" clearable size="small" @change="adjustNoChange"
                        :disabled="!isAdd" style="width: 280px">
                        <el-option v-for="(value, key) in dicts.ADJUST_NO" :label="value" :value="key" :key="key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="调价类型" prop="adjustType">
                    {{ dicts.ADJUST_TYPE[saveData.adjustType] }}
                    <!-- <el-select v-model="saveData.adjustType" placeholder="请选择调价类型" clearable size="small" @change="getRate"
                        :disabled="!isAdd" style="width: 280px">
                        <el-option v-for="(value, key) in dicts.ADJUST_TYPE" :label="value" :value="key" :key="key">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="下发收益" prop="rate">
                    <el-select v-model="saveData.rate" placeholder="请选择下发收益" style="width: 280px">
                        <el-option v-for="item in rateList" :label="item.label" :value="item.value" :key="item.value">
                        </el-option>
                    </el-select> %
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitCheck">确定新增</el-button>
                <el-button type="primary" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { AgentApi } from '@/api';
export default {
    name: "CustomForm",
    props: {
        dicts: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isAdd: {
            type: Boolean,
            default: true,
        },
        selectRow: {
            type: Object,
            default: function () {
                return {}
            },
        },
        onFresh: {
            type: Boolean,
            default: false
        },
        isOrg: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            title: '',
            saveData: {},
            open: false,
            rules: {
                agentNo2: [{ required: true, message: '请选择代理/机构编号', trigger: 'change' }],
                adjustType: [{ required: true, message: '请选择调价类型', trigger: 'change' }],
                rate: [{ required: true, message: '请选择下发收益', trigger: 'change' }],
            },
            options: [],
            agentNo: '',
            loading: false,
            rateList: []
        }
    },
    watch: {
        onFresh: function (value) {
            this.open = value;
            if (value) {
                this.resetData();
            }
        }
    },
    mounted() {
    },
    methods: {
        async remoteMethod(query) {
            if (query != '') {
                let res = await AgentApi.queryAgentNameList(query)
                if (res.success) {
                    this.options = res.data
                    console.log(res.data)
                }
            } else {
                this.options = []
            }
        },
        submitCheck() {
            this.$refs.saveOrEditForm.validate((valid) => {
                if (valid) {
                    this.submitForm()
                } else {
                    return false;
                }
            });
        },
        async resetData() {
            if (this.isAdd) {
                this.title = "新增";
                this.saveData = {};
            } else {
                this.saveData = this.selectRow;
                this.saveData.agentNo2 = this.saveData.agentNo
                this.isAdd = false;
                this.title = "修改";
                this.getRate(this.saveData.adjustType)
            }
        },
        async submitForm() {
            let result = (this.isAdd) ?
                (await AgentApi.agentAdjustCost.save(this.saveData)) :
                (await AgentApi.agentAdjustCost.edit(this.saveData.id, this.saveData));
            if (result.success) {
                this.$set(this.saveData,'adjustType','')
                this.Message.success(this.isAdd ? '添加成功' : '修改成功');
                this.cancel()
            }
        },
        cancel() {
            this.saveData = {};
            this.saveData.userName = ''
            this.saveData.agentNo = ''
            this.saveData.adjustType = ''
            this.saveData.rate = ''
            this.saveData.agentNo2 = ''
            this.commitOnClose();
        },
        commitOnClose() {
            this.$emit('on-close');
        },
        getUserName(item) {
            this.saveData.userName = item.userName
            this.saveData.agentNo = item.agentNo
            this.saveData.agentNo2 = item.agentNo
        },
        async getRate(val) {
            if (this.isAdd) {
                this.$set(this.saveData, 'rate', '')
            }
            this.rateList = []
            for (let i = 0; i <= 100; i++) {
                this.rateList.push({ value: i, label: i })
            }
        },
        adjustNoChange(val) {
            if (val == 'B') {
                this.saveData.adjustType = 'SETTLE'
            } else {
                this.saveData.adjustType = 'TRANS'
            }
            this.rateList = []
            for (let i = 0; i <= 100; i++) {
                this.rateList.push({ value: i, label: i })
            }
        }
    }
};
</script>

<style scoped></style>
